import {
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function TableStatus({
  product,
  productTransaction,
  customer,
  loading,
  periodText,
}) {
  const [customerStack, setCustomerStack] = useState([]);

  const NewProductOut = _.filter(
    product,
    (each) => each?.number_of_sales_offline_out !== 0,
  );

  const NewProductIn = _.filter(
    product,
    (each) => each?.number_of_sales_offline_in !== 0,
  );

  // console.log('periodText', periodText);

  useEffect(() => {
    // find group of product
    // console.log('orderByProduct', orderByProduct);
    // find group of customer
    const orderByCustomer = _.groupBy(
      productTransaction.rows,
      // eslint-disable-next-line no-underscore-dangle
      (order) => order?.customer?._id,
    );
    //   console.log('orderByCustomer', orderByCustomer);

    // find sum price each customer
    const priceOfCustomer = _.map(customer, (eachCustomer) => {
      // eslint-disable-next-line no-underscore-dangle
      const orderOfCustomer = orderByCustomer[eachCustomer._id];
      // console.log('orderOfCustomer', orderOfCustomer);
      const sumAmount = {
        customer: eachCustomer,
        price: _.sum(
          _.map(orderOfCustomer, (order) => {
            const sumPrice = _.sum(
              _.map(order.order, (eachOrder) => {
                // console.log('cost_price', eachOrder?.cost_price);
                // console.log('price', eachOrder?.price);
                if (periodText === 'เข้า') {
                  return eachOrder?.amount * eachOrder?.cost_price;
                }
                return eachOrder?.amount * eachOrder?.price;
              }),
            );
            // console.log('sumPrice', sumPrice);
            return sumPrice;
          }) || 0,
        ),
      };
      // console.log('sumAmount', sumAmount);
      return sumAmount;
    });

    // console.log('priceOfCustomer', priceOfCustomer);

    const newPriceOfCustomer = _.filter(
      priceOfCustomer,
      (each) => each?.price !== 0,
    );

    setCustomerStack(newPriceOfCustomer);

    return () => {};
  }, [productTransaction.rows.length]);

  const renderTableCustomer = () => (
    <>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <colgroup>
              <col width="20%" />
              <col width="60%" />
              <col width="20%" />
            </colgroup>
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ยอดซื้อ</div>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
      <div className="max-h-96 overflow-y-auto">
        <Paper>
          <TableContainer component={Paper}>
            <Table size="small" className="border-lg">
              <TableBody>
                {_.map(
                  _.sortBy(customerStack, ['customer', 'price']).reverse(),
                  (EachCustomer, index) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                      className="hover:bg-yellow-100 py-2"
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {!loading ? (
                          <div className="font-bold my-auto py-2">
                            {EachCustomer?.customer?.name}
                          </div>
                        ) : (
                          'กำลังคำนวณ...'
                        )}
                      </TableCell>
                      <TableCell>
                        {!loading &&
                          parseInt(
                            EachCustomer?.price,
                            10,
                          ).toLocaleString()}{' '}
                        บาท
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );

  const renderTopCustomers = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl">รายการลูกค้าดีเด่น</div>
        {renderTableCustomer()}
      </div>
    </Card>
  );

  const renderTableProduct = () => (
    <>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <colgroup>
              <col width="20%" />
              <col width="60%" />
              <col width="20%" />
            </colgroup>
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    {periodText === 'ออก' ? <>ขาย</> : <>ยอดนำเข้า</>}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
      <div className="max-h-96 overflow-y-auto">
        <Paper>
          <TableContainer component={Paper}>
            <Table size="small" className="border-lg">
              {periodText === 'ออก' ? (
                <TableBody>
                  {_.map(
                    _.sortBy(NewProductOut, [
                      'number_of_sales_offline_out',
                    ]).reverse(),
                    (EachProduct, index) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                        className="hover:bg-yellow-100 py-2 "
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {!loading ? (
                            <div className="font-bold my-auto py-2">
                              {EachProduct?.name}
                            </div>
                          ) : (
                            'กำลังคำนวณ...'
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="flex flex-wrap">
                            <p className="text-center pr-1">
                              {!loading &&
                                EachProduct?.number_of_sales_offline_out}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  {_.map(
                    _.sortBy(NewProductIn, [
                      'number_of_sales_offline_in',
                    ]).reverse(),
                    (EachProduct, index) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                        className="hover:bg-yellow-100 py-2 "
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {!loading ? (
                            <div className="font-bold my-auto py-2">
                              {EachProduct?.name}
                            </div>
                          ) : (
                            'กำลังคำนวณ...'
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="flex flex-wrap">
                            <p className="text-center pr-1">
                              {!loading &&
                                EachProduct?.number_of_sales_offline_in}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );

  const renderTopProduct = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl">รายการสินค้าขายดี</div>
        {renderTableProduct()}
      </div>
    </Card>
  );

  if (!loading) {
    return (
      <>
        {renderTopCustomers()}
        {renderTopProduct()}
      </>
    );
  }
  return (
    <>
      {renderTopCustomers()}
      {renderTopProduct()}
    </>
  );
}

TableStatus.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
  loading: PropTypes.bool,
  periodText: PropTypes.string,
};

TableStatus.defaultProps = {
  product: null,
  productTransaction: null,
  customer: null,
  startDate: null,
  endDate: null,
  loading: null,
};

export default TableStatus;
