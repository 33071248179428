/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
// import ProductGraph from '../../components/Graph/ProductGraph';
// import ProductDonut from '../../components/Graph/ProductDonut';
// import ProductGraphProfit from '../../components/Graph/ProductGraphProfit';
import StatusCards from '../../components/Graph/StatusCards';
import StatusCardsOnline from '../../components/Graph/StatusCardsOnline';
import TableStatus from '../../components/Graph/TableStatus';
import TableStatusOnline from '../../components/Graph/TableStatusOnline';

const MainDashboard = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const paymentTransaction = useSelector((state) => state.paymentTransaction);

  const productTransaction = useSelector((state) => state.productTransaction);
  const product = useSelector((state) => state.product);
  const customer = useSelector((state) => state.customer);

  const name = '';

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());
  const [periodText, setPeriodText] = useState('ออก');
  const transactionType = periodText;
  const DashBoardPage = true;

  console.log('productTransaction', productTransaction);
  console.log('startDate', startDate);
  console.log('endDate', endDate);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        await dispatch(actions.customerAll({ name }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomer();
    fetchMe();

    return () => {};
  }, []);

  useEffect(async () => {
    setLoading(true);
    await dispatch(
      actions.productTransactionAll({
        transactionType,
        startDate,
        endDate,
        withLog: true,
      }),
    );

    await dispatch(actions.paymentTransactionAll({ startDate, endDate }));

    await dispatch(actions.productAll({ startDate, endDate, DashBoardPage }));

    setLoading(false);
    console.log('ทำ2');
    return () => {};
  }, [startDate, endDate, transactionType]);

  const handleChangePeriod = (value) => {
    if (value === 'เข้า') {
      setPeriodText('เข้า');
    } else if (value === 'ออก') {
      setPeriodText('ออก');
    } else if (value === 'pos') {
      setPeriodText('pos');
    } else {
      setPeriodText('online');
    }
  };

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-2 gap-x-2 gap-y-2">
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
                setLoading(true);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
                setLoading(true);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );

  const renderAllStatus = () => (
    <StatusCards
      startDate={startDate}
      endDate={endDate}
      loading={loading}
      product={product?.rows}
      productTransaction={productTransaction}
      customer={customer}
      setLoading={setLoading}
      periodText={periodText}
    />
  );

  const renderAllStatusOnline = () => (
    <div>
      <div>
        <StatusCardsOnline
          startDate={startDate}
          endDate={endDate}
          product={product?.rows}
          loading={loading}
          productTransaction={productTransaction}
          paymentTransaction={paymentTransaction}
          customer={customer}
          setLoading={setLoading}
        />
      </div>
      {/* {renderAllGraph()} */}
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    me.isLoading ||
    product.isLoading ||
    customer.isLoading ||
    productTransaction.isLoading
  ) {
    return <Loading />;
  }
  if (
    !me.isLoading &&
    me.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !productTransaction.isLoading &&
    productTransaction.isCompleted
  ) {
    return (
      <div>
        {/* กำลังปรับปรุงนะครับ คาดว่าเสร็จไม่เกินวันที่ 11/04/65 */}
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!loading}
          onClose={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex justify-start pb-4">
          <div className="bg-white">
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="type" size={'small'}>
                เลือกรายการ
              </InputLabel>
              <Select
                label="เลือกรายการ"
                size={'small'}
                fullWidth
                defaultValue="ออก"
                onChange={(e) => {
                  handleChangePeriod(e.target.value);
                }}
              >
                <MenuItem value="online">ขายออนไลน์</MenuItem>
                <MenuItem value="เข้า">รายการนำเข้า</MenuItem>
                <MenuItem value="ออก">รายการส่งออก</MenuItem>
                <MenuItem value="pos">POS</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {renderPeriod()}
        {/* {renderAllStatus()}
        <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
          <TableStatus
            product={product.rows}
            productTransaction={productTransaction}
            customer={customer.rows}
            startDate={startDate}
            endDate={endDate}
            loading={loading}
          />
        </div> */}
        {transactionType === 'online'
          ? renderAllStatusOnline()
          : renderAllStatus()}
        <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
          {transactionType === 'online' ? (
            <TableStatusOnline
              product={product.rows}
              productTransaction={productTransaction}
              paymentTransaction={paymentTransaction}
              customer={customer.rows}
              startDate={startDate}
              endDate={endDate}
              loading={loading}
            />
          ) : (
            <TableStatus
              product={product.rows}
              productTransaction={productTransaction}
              customer={customer.rows}
              startDate={startDate}
              endDate={endDate}
              loading={loading}
              periodText={periodText}
            />
          )}
        </div>
      </div>
    );
  }
  return <Error message={me?.message} />;
};

MainDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainDashboard;
