/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import EditIcon from '@mui/icons-material/Edit';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function PersonalProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const setting = useSelector((state) => state.setting);

  // const urlRedirect = `${setting?.url}profile/notify/redirect`;
  // const urlTest = 'http://localhost:3000/profile/notify/redirect';
  console.log('setting', setting);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.settingAll());
    return () => {};
  }, []);

  const handleRemoveNotify = async () => {
    await dispatch(
      // eslint-disable-next-line no-underscore-dangle
      actions.employeePut(me?.userData?.id || me?.userData?._id, {
        line_token: null,
      }),
    );
    await dispatch(actions.meGet());
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderEditButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/profile/edit">
        <Button variant="contained" color="warning" startIcon={<EditIcon />}>
          แก้ไข
        </Button>
      </Link>
    </div>
  );

  const renderNofifyButton = () => {
    const lineNotifyAccessLink = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${
      setting?.lineNotify?.clientId
    }&redirect_uri=${setting?.lineNotify?.redirectURL}&scope=notify&state=${
      me?.userData?.id || me?.userData?._id
    }`;

    if (me?.userData?.line_token && me?.userData?.line_token !== '') {
      return (
        <div className="flex justify-center gap-2">
          <a href={lineNotifyAccessLink}>
            <Button variant="contained" color="warning">
              เปลี่ยนบัญชี
            </Button>
          </a>{' '}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveNotify()}
          >
            ยกเลิก
          </Button>
        </div>
      );
    }
    return (
      <a href={lineNotifyAccessLink}>
        <Button variant="contained" color="primary">
          รับการแจ้งเตือนผ่าน LINE
        </Button>
      </a>
    );
  };

  const renderDetail = () => {
    const {
      firstname = ' ',
      lastname = ' ',
      phone_number = '',
      department: { name: department_name = '' },
      image = '',
    } = me.userData;
    return (
      <div className="w-full">
        <Card>
          <div className="flex flex-wrap p-4">
            <div className="w-full">
              <div className="flex justify-center">
                <img src={image?.url} className="w-60 rounded-lg" />
              </div>
            </div>

            <div className="w-full text-center">
              <div className="text-2xl font-semibold font-display text-blue-800 py-2 mt-2">
                {firstname} {lastname}
              </div>
              <div className="py-2">เบอร์โทรศัพท์ : {phone_number}</div>
              <div className="py-2">แผนก : {department_name}</div>
              {!_.isEmpty(me?.userData?.other_departments) &&
                _.map(me?.userData?.other_departments, (_otherDept, index) => (
                  <div key={index}>{_otherDept?.name} </div>
                ))}
              {me?.userData?.driver_line?.name && (
                <div className="py-2">
                  เส้นทาง : {me?.userData?.driver_line?.name}
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const renderSetting = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold font-display  ">การตั้งค่า</div>
          <div className="flex flex-wrap w-full my-2">
            <div className="w-1/2 py-2 px-2 self-center">การแจ้งเตือน</div>
            <div className="w-1/2 py-2 px-2  flex justify-end">
              {renderNofifyButton()}
            </div>
            <hr className="w-full" />
            <div className="w-1/2 py-4 px-2 self-center">รหัสผ่าน</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to="/profile/edit-password">
                <Button variant="contained" color="inherit">
                  เปลี่ยนรหัสผ่าน
                </Button>
              </Link>
            </div>
            <hr className="w-full" />
            {/* <div className="w-1/2 py-4 px-2 self-center">
              ปรับแต่งหน้าตาการใช้งาน (Personalize)
            </div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Link to="/profile/personalize">
                <Button variant="contained" color="inherit">
                  ตกแต่ง
                </Button>
              </Link>
            </div> */}
            <hr className="w-full" />
          </div>
        </CardContent>
      </Card>
    </div>
  );

  // const renderProblemAndComplacency = () => (
  //   <div className="my-2">
  //     <Card>
  //       <CardContent>
  //         <div className="font-semibold font-display">แจ้งปัญหา/ให้คำแนะนำ</div>
  //         <div className="flex flex-wrap w-full my-2">
  //           <div className="w-1/2 py-2 px-2 self-center">
  //             แจ้งปัญหาการใช้งาน
  //           </div>
  //           <div className="w-1/2 py-2 px-2 flex justify-end ">
  //             <Link to="/profile/personalize">
  //               <Button variant="contained" color="inherit">
  //                 แจ้งปัญหา
  //               </Button>
  //             </Link>
  //           </div>
  //           <hr className="w-full" />
  //           {/* <div className="w-1/2 py-4 px-2 self-center">รหัสผ่าน</div>
  //           <div className="w-1/2 py-4 px-2  flex justify-end">
  //             <Link to="/profile/edit-password">
  //               <Button variant="contained" color="inherit">
  //                 เปลี่ยนรหัสผ่าน
  //               </Button>
  //             </Link>
  //           </div> */}
  //           <hr className="w-full" />
  //         </div>
  //       </CardContent>
  //     </Card>
  //   </div>
  // );

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">{renderEditButton()}</div>
        </div>
        {renderDetail()}
        {renderSetting()}
        {/* {renderProblemAndComplacency()} */}
      </div>
    );
  }
  return <Error />;
}
PersonalProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PersonalProfile.defaultProps = {
  title: '',
  subtitle: '',
};

export default PersonalProfile;
