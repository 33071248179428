import React, { useContext, useEffect, useState } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useHistory,
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  FileText as FileTextIcon,
  Layout as LayoutIcon,
  Sliders as SlidersIcon,
  Archive as ArchiveIcon,
  UserPlus as UserPlusIcon,
  Clipboard as ClipboardIcon,
  Percent as PercentIcon,
  // DollarSign,
  ChevronsLeft,
  ChevronsRight,
} from 'react-feather';
import _ from 'lodash';
import NavItem from './NavItem';
import { PassportAuth } from '../../contexts/AuthContext';
// import { getStorage } from '../../utils/functions/localstorage';
import accessRight from '../../utils/functions/accessRight';

const items = [
  {
    href: '/dashboard/main',
    icon: BarChartIcon,
    title: 'แดชบอร์ด',
    name: 'DASHBOARD',
    sub: [
      {
        href: '/dashboard/main',
        title: 'หน้าหลัก',
        level: 0,
      },
      // {
      //   href: '/dashboard/product',
      //   title: 'สรุปสินค้า',
      //   level: 0,
      // },
      {
        href: '/dashboard/graph',
        title: 'หน้าแสดงกราฟ',
        level: 0,
      },
      {
        href: '/dashboard/overview',
        title: 'ภาพรวม',
        level: 0,
      },
    ],
  },
  {
    href: '/pos',
    icon: FileTextIcon,
    title: 'จัดการขายหน้าร้าน',
    name: 'POS',
    sub: [
      {
        href: '/pos/main-front',
        title: 'สำหรับหน้าร้าน',
        level: 0,
      },
      {
        href: '/pos/pos-management',
        title: 'รายการสั่งซื้อหน้าร้าน',
        level: 0,
      },
      {
        href: '/pos/pos-summary',
        title: 'สรุปรายการสั่งซื้อหน้าร้าน',
        level: 0,
      },
    ],
  },
  {
    href: '/spm',
    icon: FileTextIcon,
    title: 'จัดการคำสั่งซื้อขาย',
    name: 'SPM',
    sub: [
      {
        href: '/spm/online',
        title: 'ขายออนไลน์',
        level: 0,
      },
      {
        href: '/spm/orders',
        title: 'การนำเข้าสินค้า',
        level: 0,
      },
      {
        href: '/spm/exports',
        title: 'การส่งออกสินค้า',
        level: 0,
      },
      {
        href: '/spm/online-bill',
        title: 'บิลออนไลน์',
        level: 0,
      },
    ],
  },
  {
    href: '/bms',
    icon: FileTextIcon,
    title: 'การจอง',
    name: 'RESERVE',
    sub: [
      {
        href: '/bms/booking',
        title: 'รายการจอง',
        level: 0,
      },
      {
        href: '/bms/course',
        title: 'รายการคอร์ส',
        level: 0,
      },
    ],
  },
  {
    href: '/mms',
    icon: FileTextIcon,
    name: 'MMS',
    title: 'การผลิต',
    sub: [
      {
        href: '/mms/works',
        title: 'รายการงาน',
        level: 0,
      },
      {
        href: '/mms/processes',
        title: 'ไลน์การผลิต',
        level: 0,
      },
      {
        href: '/mms/process-templates',
        title: 'รูปแบบไลน์การผลิต',
        level: 0,
      },
    ],
  },
  {
    href: '/ims',
    icon: FileTextIcon,
    title: 'คลังวัตถุดิบ',
    name: 'IMS',
    sub: [
      {
        href: '/ims/material-stock',
        title: 'จัดการสต็อกวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/materials',
        title: 'จัดการวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/material-types',
        title: 'ประเภทวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/places',
        title: 'รายชื่อคลังวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/transaction-types',
        title: 'ประเภทการดำเนินการ',
        level: 0,
      },
      {
        href: '/ims/history',
        title: 'ประวัติการจัดการวัตถุดิบ',
        level: 0,
      },
    ],
  },
  {
    href: '/wms',
    icon: ArchiveIcon,
    title: 'คลังสินค้า',
    name: 'WMS',
    sub: [
      {
        href: '/wms/products',
        title: 'จัดการสินค้า',
        level: 0,
      },
      // {
      //   href: '/wms/product-stock',
      //   title: 'จัดการสต็อกสินค้า',
      //   level: 0,
      // },
      {
        href: '/wms/product-types',
        title: 'ประเภทสินค้า',
        level: 0,
      },
      {
        href: '/wms/brand',
        title: 'แบรนด์',
        level: 0,
      },
      // {
      //   href: '/wms/warehouses',
      //   title: 'จัดการคลังสินค้า',
      //   level: 0,
      // },
      // {
      //   href: '/wms/transaction-types',
      //   title: 'ประเภทการดำเนินการ',
      //   level: 0,
      // },
      // {
      //   href: '/wms/history',
      //   title: 'ประวัติการจัดการสต๊อกสินค้า',
      //   level: 0,
      // },
    ],
  },
  {
    href: '/history',
    icon: ClipboardIcon,
    title: 'จัดการประวัติ',
    name: 'HISTORY',
    sub: [
      {
        href: '/history/online',
        title: 'ประวัติการสั่งซื้อ',
        level: 0,
      },
      {
        href: '/history/wms/historyproduct',
        title: 'ประวัติการลบรายการสั่งซื้อ',
        level: 0,
      },

      {
        href: '/history/wms/history',
        title: 'ประวัติการจัดการสต๊อกสินค้า',
        level: 0,
      },
    ],
  },
  {
    href: '/crm',
    icon: UsersIcon,
    title: 'ลูกค้าสัมพันธ์',
    name: 'CRM',
    sub: [
      {
        href: '/crm/customers',
        title: 'รายการลูกค้า',
        level: 0,
      },
      {
        href: '/crm/customer-types',
        title: 'ประเภทลูกค้า',
        level: 0,
      },
      {
        href: '/crm/users',
        title: 'จัดการผู้ใช้',
        level: 0,
      },
    ],
  },
  {
    href: '/hrms',
    icon: UserPlusIcon,
    title: 'ทรัพยากรบุคคล',
    name: 'HRMS',
    sub: [
      {
        href: '/hrms/employee',
        title: 'จัดการพนักงาน',
        level: 0,
      },
      {
        href: '/hrms/department',
        title: 'จัดการแผนก',
        level: 0,
      },
      {
        href: '/hrms/driver-line',
        title: 'จัดการเส้นทาง',
        level: 0,
      },
      {
        href: '/hrms/role-types',
        title: 'จัดการบทบาท',
        level: 0,
      },
      {
        href: '/hrms/user',
        title: 'จัดการผู้ใช้',
        level: 0,
      },
    ],
  },
  {
    href: '/cms',
    icon: LayoutIcon,
    title: 'จัดการเว็บไซต์',
    name: 'CMS',
    sub: [
      {
        href: '/cms/main-site',
        title: 'หน้าหลักของเว็บไซต์',
        level: 0,
      },
      {
        href: '/cms/setting-layout',
        title: 'จัดการเลเอาท์',
        level: 0,
      },
      {
        href: '/cms/contact-site',
        title: 'ข้อมูลการติดต่อ',
        level: 0,
      },
      {
        href: '/cms/sponsor',
        title: 'สปอนเซอร์/พาร์ทเนอร์',
        level: 0,
      },
      {
        href: '/cms/business-policy',
        title: 'นโยบายทางธุรกิจ',
        level: 0,
      },
      {
        href: '/cms/cookies-policy',
        title: 'นโยบายการใช้งานคุกกี้',
        level: 0,
      },
      {
        href: '/cms/privacy-policy',
        title: 'นโยบายความเป็นส่วนตัว',
        level: 0,
      },
    ],
  },
  {
    href: '/discount',
    icon: PercentIcon,
    title: 'จัดการส่วนลด',
    name: 'DISCOUNT',
    sub: [
      {
        href: '/discount/promotion',
        title: 'จัดการส่วนลด',
        level: 0,
        name: 'DISCOUNT',
      },
      {
        href: '/discount/coupon',
        title: 'จัดการคูปอง',
        level: 0,
        name: 'DISCOUNT',
      },
    ],
  },
  {
    href: '/setting',
    icon: SlidersIcon,
    title: 'ตั้งค่าระบบ',
    name: 'SETTING',
    sub: [
      {
        href: '/setting/system',
        title: 'การตั้งค่าทั่วไป',
        level: 0,
        name: 'SETTING',
        sub: [
          {
            href: '/setting/system',
            title: 'ตั้งค่าข้อมูลระบบ',
            level: 0,
          },
          {
            href: '/setting/modules',
            title: 'โมดูลของระบบ',
            level: 0,
          },
        ],
      },
      {
        href: '/setting/delivery',
        title: 'การจัดส่งสินค้า',
        level: 0,
        name: 'SETTING',
        sub: [
          {
            href: '/setting/delivery',
            title: 'ตั้งค่าราคาการจัดส่ง',
            level: 0,
          },
          // {
          //   href: '/setting/area-delivery',
          //   title: 'ตั้งค่าพื้นที่การจัดส่ง',
          //   level: 0,
          // },
        ],
      },
      // {
      //   href: '/setting/promotion',
      //   title: 'จัดการส่วนลด',
      //   level: 0,
      //   name: 'SETTING',
      // },
      {
        href: '/setting/SEOManagement',
        title: 'จัดการ SEO',
        level: 0,
        name: 'SETTING',
      },
    ],
  },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const { firstname = '', lastname = '' } = me?.userData;
  const [isTaskbarOpen, setIsTaskbarOpen] = useState(true);

  const availableModule = useSelector((state) => state.availableModule);
  const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    jobTitle: me?.userData?.department?.name,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box className="px-6 py-2">
        <div className="my-2">
          <Button
            onClick={() => setIsTaskbarOpen(!isTaskbarOpen)}
            fullWidth
            color="inherit"
            size="large"
            className="my-2"
          >
            <ChevronsLeft /> ปิดแถบด้านข้าง
          </Button>
        </div>

        <div className="p-2 rounded-xl flex flex-wrap bg-gray-50">
          <div className="w-full lg:w-1/3">
            <Avatar
              component={RouterLink}
              src={me?.userData?.image?.url || ''}
              sx={{
                cursor: 'pointer',
                width: 48,
                height: 48,
              }}
              to="/profile"
            />
          </div>
          <div className="w-full lg:w-2/3">
            <Typography color="textPrimary" variant="body2">
              {user.name}
            </Typography>
            <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
              {!_.isEmpty(me?.userData?.other_departments) &&
                _.map(me?.userData?.other_departments, (_otherDept, index) => (
                  <div key={index}>{_otherDept?.name} </div>
                ))}
            </Typography>{' '}
            <Link to="/profile">
              <Typography
                color="primary"
                variant="body2"
                className="pt-2 cursor-pointer hover:text-green-800"
              >
                <i className="fas fa-edit" /> แก้ไขโปรไฟล์
              </Typography>
            </Link>
          </div>
          <div></div>
        </div>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (accessRight(me, item.name, 0, availableModule)) {
              return (
                <div key={item.title}>
                  <NavItem
                    href={item.href}
                    title={item.title}
                    icon={item.icon}
                    sub={item.sub}
                    me={me}
                    moduleName={item.name}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        {!isTaskbarOpen && (
          <div className="fixed left-0  top-15 z-40">
            <Button
              variant="contained"
              color="inherit"
              size="large"
              onClick={() => setIsTaskbarOpen(!isTaskbarOpen)}
            >
              <ChevronsRight />
            </Button>
          </div>
        )}
        <Drawer
          anchor="left"
          open={isTaskbarOpen}
          variant="persistent"
          className={` ${isTaskbarOpen ? 'w-64' : ''}`}
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}{' '}
        </Drawer>
      </Hidden>{' '}
    </div>
  );
};

DashboardSidebar.propTypes = {
  me: PropTypes.object,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
