export const PAYMENT_STATUS = {
  successful: {
    status_code: 'successful',
    description: 'ชำระเงินแล้ว',
  },
  รอการชำระเงิน: {
    status_code: 'รอการชำระเงิน',
    description: 'รอการชำระเงิน',
  },
  pending: {
    status_code: 'pending',
    description: 'รอการชำระเงิน',
  },
};

export default PAYMENT_STATUS;
