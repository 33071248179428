// common type
export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

export const INFORMATION_ALL = 'INFORMATION_ALL';
export const INFORMATION_PUT = 'INFORMATION_PUT';
export const INFORMATION_LOADING = 'INFORMATION_LOADING';
export const INFORMATION_ERROR = 'INFORMATION_ERROR';

export const NOTIFY_REQ_TOKEN = 'NOTIFY_REQ_TOKEN';
export const NOTIFY_DIRECT = 'NOTIFY_DIRECT';
export const NOTIFY_DEPARTMENT = 'NOTIFY_DEPARTMENT';
export const NOTIFY_LOADING = 'NOTIFY_LOADING';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';

// feature type
export const INFO_GET = 'INFO_GET';
export const INFO_CREATE = 'INFO_CREATE';
export const INFO_PUT = 'INFO_PUT';
export const INFO_RESET = 'INFO_RESET';
export const INFO_LOADING = 'INFO_LOADING';
export const INFO_ERROR = 'INFO_ERROR';

export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const PORTFOLIO_ALL = 'PORTFOLIO_ALL';
export const PORTFOLIO_GET = 'PORTFOLIO_GET';
export const PORTFOLIO_POST = 'PORTFOLIO_POST';
export const PORTFOLIO_PUT = 'PORTFOLIO_PUT';
export const PORTFOLIO_DEL = 'PORTFOLIO_DEL';
export const PORTFOLIO_RESET = 'PORTFOLIO_RESET';
export const PORTFOLIO_LOADING = 'PORTFOLIO_LOADING';
export const PORTFOLIO_ERROR = 'PORTFOLIO_ERROR';

export const CUSTOMER_TYPE_ALL = 'CUSTOMER_TYPE_ALL';
export const CUSTOMER_TYPE_GET = 'CUSTOMER_TYPE_GET';
export const CUSTOMER_TYPE_POST = 'CUSTOMER_TYPE_POST';
export const CUSTOMER_TYPE_PUT = 'CUSTOMER_TYPE_PUT';
export const CUSTOMER_TYPE_DEL = 'CUSTOMER_TYPE_DEL';
export const CUSTOMER_TYPE_LOADING = 'CUSTOMER_TYPE_LOADING';
export const CUSTOMER_TYPE_ERROR = 'CUSTOMER_TYPE_ERROR';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';

export const DRIVERLINE_ALL = 'DRIVERLINE_ALL';
export const DRIVERLINE_GET = 'DRIVERLINE_GET';
export const DRIVERLINE_POST = 'DRIVERLINE_POST';
export const DRIVERLINE_PUT = 'DRIVERLINE_PUT';
export const DRIVERLINE_DEL = 'DRIVERLINE_DEL';
export const DRIVERLINE_LOADING = 'DRIVERLINE_LOADING';
export const DRIVERLINE_ERROR = 'DRIVERLINE_ERROR';

export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const MATERIALTYPES_ALL = 'MATERIALTYPES_ALL';
export const MATERIALTYPES_GET = 'MATERIALTYPES_GET';
export const MATERIALTYPES_POST = 'MATERIALTYPES_POST';
export const MATERIALTYPES_PUT = 'MATERIALTYPES_PUT';
export const MATERIALTYPES_DEL = 'MATERIALTYPES_DEL';
export const MATERIALTYPES_LOADING = 'MATERIALTYPES_LOADING';
export const MATERIALTYPES_ERROR = 'MATERIALTYPES_ERROR';

export const MATERIAL_ALL = 'MATERIAL_ALL';
export const MATERIAL_GET = 'MATERIAL_GET';
export const MATERIAL_GET_BY_TYPE = 'MATERIAL_GET_BY_TYPE';
export const MATERIAL_POST = 'MATERIAL_POST';
export const MATERIAL_PUT = 'MATERIAL_PUT';
export const MATERIAL_DEL = 'MATERIAL_DEL';
export const MATERIAL_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';

export const MATERIAL_TRANSACTION_TYPES_ALL = 'MATERIAL_TRANSACTION_TYPES_ALL';
export const MATERIAL_TRANSACTION_TYPES_GET = 'MATERIAL_TRANSACTION_TYPES_GET';
export const MATERIAL_TRANSACTION_TYPES_POST =
  'MATERIAL_TRANSACTION_TYPES_POST';
export const MATERIAL_TRANSACTION_TYPES_PUT = 'MATERIAL_TRANSACTION_TYPES_PUT';
export const MATERIAL_TRANSACTION_TYPES_DEL = 'MATERIAL_TRANSACTION_TYPES_DEL';
export const MATERIAL_TRANSACTION_TYPES_LOADING =
  'MATERIAL_TRANSACTION_TYPES_LOADING';
export const MATERIAL_TRANSACTION_TYPES_ERROR =
  'MATERIAL_TRANSACTION_TYPES_ERROR';

export const PLACE_ALL = 'PLACE_ALL';
export const PLACE_GET = 'PLACE_GET';
export const PLACE_POST = 'PLACE_POST';
export const PLACE_PUT = 'PLACE_PUT';
export const PLACE_DEL = 'PLACE_DEL';
export const PLACE_LOADING = 'PLACE_LOADING';
export const PLACE_ERROR = 'PLACE_ERROR';

export const PRODUCT_TRANSACTION_ALL = 'PRODUCT_TRANSACTION_ALL';
export const PRODUCT_TRANSACTION_GET = 'PRODUCT_TRANSACTION_GET';
export const PRODUCT_TRANSACTION_POST = 'PRODUCT_TRANSACTION_POST';
export const PRODUCT_TRANSACTION_PUT = 'PRODUCT_TRANSACTION_PUT';
export const PRODUCT_TRANSACTION_DEL = 'PRODUCT_TRANSACTION_DEL';
export const PRODUCT_TRANSACTION_LOADING = 'PRODUCT_TRANSACTION_LOADING';
export const PRODUCT_TRANSACTION_ERROR = 'PRODUCT_TRANSACTION_ERROR';
export const PRODUCT_TRANSACTION_RESET = 'PRODUCT_TRANSACTION_RESET';

export const PRODUCT_TRANSACTIONLOG_ALL = 'PRODUCT_TRANSACTIONLOG_ALL';
export const PRODUCT_TRANSACTIONLOG_GET = 'PRODUCT_TRANSACTIONLOG_GET';
export const PRODUCT_TRANSACTIONLOG_POST = 'PRODUCT_TRANSACTIONLOG_POST';
export const PRODUCT_TRANSACTIONLOG_PUT = 'PRODUCT_TRANSACTIONLOG_PUT';
export const PRODUCT_TRANSACTIONLOG_DEL = 'PRODUCT_TRANSACTIONLOG_DEL';
export const PRODUCT_TRANSACTIONLOG_LOADING = 'PRODUCT_TRANSACTIONLOG_LOADING';
export const PRODUCT_TRANSACTIONLOG_ERROR = 'PRODUCT_TRANSACTIONLOG_ERROR';
export const PRODUCT_TRANSACTIONLOG_RESET = 'PRODUCT_TRANSACTIONLOG_RESET';

export const MATERIAL_STOCK_LOT_ALL = 'MATERIAL_STOCK_LOT_ALL';
export const MATERIAL_STOCK_LOT_GET = 'MATERIAL_STOCK_LOT_GET';
export const MATERIAL_STOCK_LOT_POST = 'MATERIAL_STOCK_LOT_POST';
export const MATERIAL_STOCK_LOT_PUT = 'MATERIAL_STOCK_LOT_PUT';
export const MATERIAL_STOCK_LOT_DEL = 'MATERIAL_STOCK_LOT_DEL';
export const MATERIAL_STOCK_LOT_LOADING = 'MATERIAL_STOCK_LOT_LOADING';
export const MATERIAL_STOCK_LOT_ERROR = 'MATERIAL_STOCK_LOT_ERROR';

export const ORDER_ALL = 'ORDER_ALL';
export const ORDER_GET = 'ORDER_GET';
export const ORDER_POST = 'ORDER_POST';
export const ORDER_PUT = 'ORDER_PUT';
export const ORDER_DEL = 'ORDER_DEL';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';

export const PROCESS_TEMPLATE_ALL = 'PROCESS_TEMPLATE_ALL';
export const PROCESS_TEMPLATE_GET = 'PROCESS_TEMPLATE_GET';
export const PROCESS_TEMPLATE_POST = 'PROCESS_TEMPLATE_POST';
export const PROCESS_TEMPLATE_PUT = 'PROCESS_TEMPLATE_PUT';
export const PROCESS_TEMPLATE_DEL = 'PROCESS_TEMPLATE_DEL';
export const PROCESS_TEMPLATE_LOADING = 'PROCESS_TEMPLATE_LOADING';
export const PROCESS_TEMPLATE_ERROR = 'PROCESS_TEMPLATE_ERROR';

export const PROCESS_OF_ORDER_ALL = 'PROCESS_OF_ORDER_ALL';
export const PROCESS_OF_ORDER_GET = 'PROCESS_OF_ORDER_GET';
export const PROCESS_OF_ORDER_POST = 'PROCESS_OF_ORDER_POST';
export const PROCESS_OF_ORDER_PUT = 'PROCESS_OF_ORDER_PUT';
export const PROCESS_OF_ORDER_DEL = 'PROCESS_OF_ORDER_DEL';
export const PROCESS_OF_ORDER_LOADING = 'PROCESS_OF_ORDER_LOADING';
export const PROCESS_OF_ORDER_ERROR = 'PROCESS_OF_ORDER_ERROR';

export const WAREHOUSE_ALL = 'WAREHOUSE_ALL';
export const WAREHOUSE_GET = 'WAREHOUSE_GET';
export const WAREHOUSE_POST = 'WAREHOUSE_POST';
export const WAREHOUSE_PUT = 'WAREHOUSE_PUT';
export const WAREHOUSE_DEL = 'WAREHOUSE_DEL';
export const WAREHOUSE_LOADING = 'WAREHOUSE_LOADING';
export const WAREHOUSE_ERROR = 'WAREHOUSE_ERROR';

export const PRODUCTTYPES_ALL = 'PRODUCTTYPES_ALL';
export const PRODUCTTYPES_GET = 'PRODUCTTYPES_GET';
export const PRODUCTTYPES_POST = 'PRODUCTTYPES_POST';
export const PRODUCTTYPES_PUT = 'PRODUCTTYPES_PUT';
export const PRODUCTTYPES_DEL = 'PRODUCTTYPES_DEL';
export const PRODUCTTYPES_LOADING = 'PRODUCTTYPES_LOADING';
export const PRODUCTTYPES_ERROR = 'PRODUCTTYPES_ERROR';

export const SETTING_LAYOUT_ALL = 'SETTING_LAYOUT_ALL';
export const SETTING_LAYOUT_GET = 'SETTING_LAYOUT_GET';
export const SETTING_LAYOUT_POST = 'SETTING_LAYOUT_POST';
export const SETTING_LAYOUT_PUT = 'SETTING_LAYOUT_PUT';
export const SETTING_LAYOUT_DEL = 'SETTING_LAYOUT_DEL';
export const SETTING_LAYOUT_LOADING = 'SETTING_LAYOUT_LOADING';
export const SETTING_LAYOUT_ERROR = 'SETTING_LAYOUT_ERROR';

export const SPONSOR_ALL = 'SPONSOR_ALL';
export const SPONSOR_GET = 'SPONSOR_GET';
export const SPONSOR_POST = 'SPONSOR_POST';
export const SPONSOR_PUT = 'SPONSOR_PUT';
export const SPONSOR_DEL = 'SPONSOR_DEL';
export const SPONSOR_LOADING = 'SPONSOR_LOADING';
export const SPONSOR_ERROR = 'SPONSOR_ERROR';

export const BRAND_ALL = 'BRAND_ALL';
export const BRAND_GET = 'BRAND_GET';
export const BRAND_POST = 'BRAND_POST';
export const BRAND_PUT = 'BRAND_PUT';
export const BRAND_DEL = 'BRAND_DEL';
export const BRAND_LOADING = 'BRAND_LOADING';
export const BRAND_ERROR = 'BRAND_ERROR';

export const COUPON_ALL = 'COUPON_ALL';
export const COUPON_GET = 'COUPON_GET';
export const COUPON_POST = 'COUPON_POST';
export const COUPON_PUT = 'COUPON_PUT';
export const COUPON_DEL = 'COUPON_DEL';
export const COUPON_LOADING = 'COUPON_LOADING';
export const COUPON_ERROR = 'COUPON_ERROR';

export const POLICY_ALL = 'POLICY_ALL';
export const POLICY_GET = 'POLICY_GET';
export const POLICY_POST = 'POLICY_POST';
export const POLICY_PUT = 'POLICY_PUT';
export const POLICY_DEL = 'POLICY_DEL';
export const POLICY_LOADING = 'POLICY_LOADING';
export const POLICY_ERROR = 'POLICY_ERROR';

export const PRODUCT_ALL = 'PRODUCT_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_GET_BY_TYPE = 'PRODUCT_GET_BY_TYPE';
export const PRODUCT_POST = 'PRODUCT_POST';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_DEL = 'PRODUCT_DEL';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

export const PRODUCT_TRANSACTION_TYPES_ALL = 'PRODUCT_TRANSACTION_TYPES_ALL';
export const PRODUCT_TRANSACTION_TYPES_GET = 'PRODUCT_TRANSACTION_TYPES_GET';
export const PRODUCT_TRANSACTION_TYPES_POST = 'PRODUCT_TRANSACTION_TYPES_POST';
export const PRODUCT_TRANSACTION_TYPES_PUT = 'PRODUCT_TRANSACTION_TYPES_PUT';
export const PRODUCT_TRANSACTION_TYPES_DEL = 'PRODUCT_TRANSACTION_TYPES_DEL';
export const PRODUCT_TRANSACTION_TYPES_LOADING =
  'PRODUCT_TRANSACTION_TYPES_LOADING';
export const PRODUCT_TRANSACTION_TYPES_ERROR =
  'PRODUCT_TRANSACTION_TYPES_ERROR';

export const PRODUCT_STOCK_LOT_ALL = 'PRODUCT_STOCK_LOT_ALL';
export const PRODUCT_STOCK_LOT_GET = 'PRODUCT_STOCK_LOT_GET';
export const PRODUCT_STOCK_LOT_POST = 'PRODUCT_STOCK_LOT_POST';
export const PRODUCT_STOCK_LOT_PUT = 'PRODUCT_STOCK_LOT_PUT';
export const PRODUCT_STOCK_LOT_DEL = 'PRODUCT_STOCK_LOT_DEL';
export const PRODUCT_STOCK_LOT_LOADING = 'PRODUCT_STOCK_LOT_LOADING';
export const PRODUCT_STOCK_LOT_ERROR = 'PRODUCT_STOCK_LOT_ERROR';

export const LOG_ALL = 'LOG_ALL';
export const LOG_GET = 'LOG_GET';
export const LOG_CREATE = 'LOG_CREATE';
export const LOG_UPDATE = 'LOG_UPDATE';
export const LOG_DELETE = 'LOG_DELETE';
export const LOG_ERROR = 'LOG_ERROR';
export const LOG_LOADING = 'LOG_LOADING';

export const SERIAL_ALL = 'SERIAL_ALL';
export const SERIAL_GET = 'SERIAL_GET';
export const SERIAL_CREATE = 'SERIAL_CREATE';
export const SERIAL_UPDATE = 'SERIAL_UPDATE';
export const SERIAL_DELETE = 'SERIAL_DELETE';
export const SERIAL_ERROR = 'SERIAL_ERROR';

export const AVAILABLE_MODULE_ALL = 'AVAILABLE_MODULE_ALL';
export const AVAILABLE_MODULE_ERROR = 'AVAILABLE_MODULE_ERROR';
export const AVAILABLE_MODULE_LOADING = 'AVAILABLE_MODULE_LOADING';
export const AVAILABLE_MODULE_PUT = 'AVAILABLE_MODULE_PUT';

export const PAYMENT_TRANSACTION_ALL = 'PAYMENT_TRANSACTION_ALL';
export const PAYMENT_TRANSACTION_GET = 'PAYMENT_TRANSACTION_GET';
export const PAYMENT_TRANSACTION_PUT = 'PAYMENT_TRANSACTION_PUT';
export const PAYMENT_TRANSACTION_DEL = 'PAYMENT_TRANSACTION_DEL';
export const PAYMENT_TRANSACTION_LOADING = 'PAYMENT_TRANSACTION_LOADING';
export const PAYMENT_TRANSACTION_ERROR = 'PAYMENT_TRANSACTION_ERROR';

export const ONLINE_ORDER_ALL = 'ONLINE_ORDER_ALL';
export const ONLINE_ORDER_CREATE = 'ONLINE_ORDER_CREATE';
export const ONLINE_ORDER_GET = 'ONLINE_ORDER_GET';
export const ONLINE_ORDER_PUT = 'ONLINE_ORDER_PUT';
export const ONLINE_ORDER_DEL = 'ONLINE_ORDER_DEL';
export const ONLINE_ORDER_LOADING = 'ONLINE_ORDER_LOADING';
export const ONLINE_ORDER_ERROR = 'ONLINE_ORDER_ERROR';

export const SETTING_ALL = 'SETTING_ALL';
export const SETTING_PUT = 'SETTING_PUT';
export const SETTING_LOADING = 'SETTING_LOADING';
export const SETTING_ERROR = 'SETTING_ERROR';

export const OPTION_ALL = 'OPTION_ALL';
export const OPTION_GET = 'OPTION_GET';
export const OPTION_CREATE = 'OPTION_CREATE';
export const OPTION_UPDATE = 'OPTION_UPDATE';
export const OPTION_DELETE = 'OPTION_DELETE';
export const OPTION_ERROR = 'OPTION_ERROR';
export const OPTION_LOADING = 'OPTION_LOADING';

export const DELIVERY_PRICE_ALL = 'DELIVERY_PRICE_ALL';
export const DELIVERY_PRICE_GET = 'DELIVERY_PRICE_GET';
export const DELIVERY_PRICE_CREATE = 'DELIVERY_PRICE_CREATE';
export const DELIVERY_PRICE_UPDATE = 'DELIVERY_PRICE_UPDATE';
export const DELIVERY_PRICE_DELETE = 'DELIVERY_PRICE_DELETE';
export const DELIVERY_PRICE_ERROR = 'DELIVERY_PRICE_ERROR';

export const DELIVERY_PROVIDER_ALL = 'DELIVERY_PROVIDER_ALL';
export const DELIVERY_PROVIDER_GET = 'DELIVERY_PROVIDER_GET';
export const DELIVERY_PROVIDER_CREATE = 'DELIVERY_PROVIDER_CREATE';
export const DELIVERY_PROVIDER_UPDATE = 'DELIVERY_PROVIDER_UPDATE';
export const DELIVERY_PROVIDER_DELETE = 'DELIVERY_PROVIDER_DELETE';
export const DELIVERY_PROVIDER_ERROR = 'DELIVERY_PROVIDER_ERROR';

export const STOCK_CHECKING_ALL = 'STOCK_CHECKING_ALL';
export const STOCK_CHECKING_GET = 'STOCK_CHECKING_GET';
export const STOCK_CHECKING_CREATE = 'STOCK_CHECKING_CREATE';
export const STOCK_CHECKING_UPDATE = 'STOCK_CHECKING_UPDATE';
export const STOCK_CHECKING_DELETE = 'STOCK_CHECKING_DELETE';
export const STOCK_CHECKING_ERROR = 'STOCK_CHECKING_ERROR';

export const PROMOTION_WITH_CUSTOMER_TYPE_ALL =
  'PROMOTION_WITH_CUSTOMER_TYPE_ALL';
export const PROMOTION_WITH_CUSTOMER_TYPE_GET =
  'PROMOTION_WITH_CUSTOMER_TYPE_GET';
export const PROMOTION_WITH_CUSTOMER_TYPE_CREATE =
  'PROMOTION_WITH_CUSTOMER_TYPE_CREATE';
export const PROMOTION_WITH_CUSTOMER_TYPE_UPDATE =
  'PROMOTION_WITH_CUSTOMER_TYPE_UPDATE';
export const PROMOTION_WITH_CUSTOMER_TYPE_DELETE =
  'PROMOTION_WITH_CUSTOMER_TYPE_DELETE';
export const PROMOTION_WITH_CUSTOMER_TYPE_ERROR =
  'PROMOTION_WITH_CUSTOMER_TYPE_ERROR';

export const DISCOUNT_PAYMENT_ALL = 'DISCOUNT_PAYMENT_ALL';
export const DISCOUNT_PAYMENT_GET = 'DISCOUNT_PAYMENT_GET';
export const DISCOUNT_PAYMENT_CREATE = 'DISCOUNT_PAYMENT_CREATE';
export const DISCOUNT_PAYMENT_UPDATE = 'DISCOUNT_PAYMENT_UPDATE';
export const DISCOUNT_PAYMENT_DELETE = 'DISCOUNT_PAYMENT_DELETE';
export const DISCOUNT_PAYMENT_ERROR = 'DISCOUNT_PAYMENT_ERROR';

export const PROMOTION_WITH_PRODUCT_TYPE_ALL =
  'PROMOTION_WITH_PRODUCT_TYPE_ALL';
export const PROMOTION_WITH_PRODUCT_TYPE_GET =
  'PROMOTION_WITH_PRODUCT_TYPE_GET';
export const PROMOTION_WITH_PRODUCT_TYPE_CREATE =
  'PROMOTION_WITH_PRODUCT_TYPE_CREATE';
export const PROMOTION_WITH_PRODUCT_TYPE_UPDATE =
  'PROMOTION_WITH_PRODUCT_TYPE_UPDATE';
export const PROMOTION_WITH_PRODUCT_TYPE_DELETE =
  'PROMOTION_WITH_PRODUCT_TYPE_DELETE';
export const PROMOTION_WITH_PRODUCT_TYPE_ERROR =
  'PROMOTION_WITH_PRODUCT_TYPE_ERROR';

export const PROMOTION_WITH_WHOLESALE_ALL = 'PROMOTION_WITH_WHOLESALE_ALL';
export const PROMOTION_WITH_WHOLESALE_GET = 'PROMOTION_WITH_WHOLESALE_GET';
export const PROMOTION_WITH_WHOLESALE_CREATE =
  'PROMOTION_WITH_WHOLESALE_CREATE';
export const PROMOTION_WITH_WHOLESALE_UPDATE =
  'PROMOTION_WITH_WHOLESALE_UPDATE';
export const PROMOTION_WITH_WHOLESALE_DELETE =
  'PROMOTION_WITH_WHOLESALE_DELETE';
export const PROMOTION_WITH_WHOLESALE_ERROR = 'PROMOTION_WITH_WHOLESALE_ERROR';

export const PROMOTION_BILL_PRICE_ALL = 'PROMOTION_BILL_PRICE_ALL';
export const PROMOTION_BILL_PRICE_GET = 'PROMOTION_BILL_PRICE_GET';
export const PROMOTION_BILL_PRICE_CREATE = 'PROMOTION_BILL_PRICE_CREATE';
export const PROMOTION_BILL_PRICE_UPDATE = 'PROMOTION_BILL_PRICE_UPDATE';
export const PROMOTION_BILL_PRICE_DELETE = 'PROMOTION_BILL_PRICE_DELETE';
export const PROMOTION_BILL_PRICE_ERROR = 'PROMOTION_BILL_PRICE_ERROR';

export const SEOMANAGEMENT_ALL = 'SEOMANAGEMENT_ALL';
export const SEOMANAGEMENT_GET = 'SEOMANAGEMENT_GET';
export const SEOMANAGEMENT_CREATE = 'SEOMANAGEMENT_CREATE';
export const SEOMANAGEMENT_UPDATE = 'SEOMANAGEMENT_UPDATE';
export const SEOMANAGEMENT_DELETE = 'SEOMANAGEMENT_DELETE';
export const SEOMANAGEMENT_ERROR = 'SEOMANAGEMENT_ERROR';
export const SEOMANAGEMENT_LOADING = 'SEOMANAGEMENT_LOADING';
export const SEOMANAGEMENT_RESET = 'SEOMANAGEMENT_RESET';

export const ORDER_MESSAGE_ALL = 'ORDER_MESSAGE_ALL';
export const ORDER_MESSAGE_GET = 'ORDER_MESSAGE_GET';
export const ORDER_MESSAGE_POST = 'ORDER_MESSAGE_POST';
export const ORDER_MESSAGE_PUT = 'ORDER_MESSAGE_PUT';
export const ORDER_MESSAGE_DEL = 'ORDER_MESSAGE_DEL';
export const ORDER_MESSAGE_LOADING = 'ORDER_MESSAGE_LOADING';
export const ORDER_MESSAGE_ERROR = 'ORDER_MESSAGE_ERROR';

export const COURSE_ALL = 'COURSE_ALL';
export const COURSE_GET = 'COURSE_GET';
export const COURSE_POST = 'COURSE_POST';
export const COURSE_PUT = 'COURSE_PUT';
export const COURSE_DEL = 'COURSE_DEL';
export const COURSE_LOADING = 'COURSE_LOADING';
export const COURSE_ERROR = 'COURSE_ERROR';

export const BOOKING_ALL = 'BOOKING_ALL';
export const BOOKING_GET = 'BOOKING_GET';
export const BOOKING_POST = 'BOOKING_POST';
export const BOOKING_PUT = 'BOOKING_PUT';
export const BOOKING_DEL = 'BOOKING_DEL';
export const BOOKING_LOADING = 'BOOKING_LOADING';
export const BOOKING_ERROR = 'BOOKING_ERROR';
