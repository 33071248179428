import React, { useEffect, useCallback } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import _ from 'lodash';

const FileUpload = ({ isOpen, handleClose, files, setFiles }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files],
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  // const removeAll = () => {
  //   setFiles([]);
  // };

  useEffect(() => {
    setFiles(acceptedFiles);
    return () => {};
  }, [_.size(acceptedFiles)]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>อัพโหลดไฟล์</DialogTitle>

      <DialogContent>
        <section className="container">
          <small>( อัพโหลดได้สูงสุด 5 ไฟล์ )</small>
          <div
            {...getRootProps({ className: 'dropzone' })}
            className="cursor-pointer"
          >
            <input {...getInputProps()} />
            <p className="font-system w-72 m-1 p-4 bg-gray-50 border ">
              ลากไฟล์มาที่นี่ หรือ กดที่นี่เพื่อเลือกไฟล์
            </p>
          </div>
          <aside>
            {_.size(files) > 0 && (
              <ul className="list-disc list-inside mx-2 my-3">
                {_.map(files, (_file, index) => (
                  <div className="flex justify-between">
                    <li className="my-auto" key={index}>
                      {_file?.path}
                    </li>
                    <Button onClick={removeFile(_file)}>ลบ</Button>
                  </div>
                ))}
              </ul>
            )}
          </aside>
        </section>
        {!_.isEmpty(files) && (
          <div className="flex justify-center my-2">
            <Button
              color="success"
              variant="contained"
              onClick={() => handleClose()}
            >
              ยืนยัน
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.object),
  setFiles: PropTypes.func,
};
