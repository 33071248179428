import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function CustomerForm({
  errors,
  customerType,
  customer,
  Controller,
  control,
  // supplierType,
  addSupplier,
  setAddSupplier,
  driverLine,
  setAddUser,
  addUser,
  dropShip,
  setDropShip,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={customer ? customer?.type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                หมวดหมู่*
              </InputLabel>
              <Select
                {...field}
                label="หมวดหมู่"
                size={'small'}
                fullWidth
                required
              >
                {_.size(customerType?.rows) ? (
                  _.map(customerType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'organization_type'}
          control={control}
          defaultValue={customer ? customer.organization_type : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทองค์กร
              </InputLabel>
              <Select
                {...field}
                label="ประเภทองค์กร"
                size={'small'}
                fullWidth
                required
              >
                <MenuItem value="บริษัท">บุคคลทั่วไป</MenuItem>
                <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'code_name'}
          control={control}
          defaultValue={customer ? customer.code_name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสลูกค้า (ถ้ามี)"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={customer ? customer.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'address'}
          control={control}
          defaultValue={customer ? customer.address : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              rows={3}
              multiline={true}
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'taxes'}
          control={control}
          defaultValue={customer ? customer.taxes : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'phone_number'}
          control={control}
          defaultValue={customer ? customer.phone_number : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'email'}
          control={control}
          defaultValue={customer ? customer.email : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField {...field} label="อีเมล" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'fax'}
          control={control}
          defaultValue={customer ? customer.fax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField {...field} label="แฟกซ์" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'payment'}
          control={control}
          defaultValue={customer ? customer.payment : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทการชำระเงิน
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการชำระเงิน"
                size={'small'}
                fullWidth
              >
                <MenuItem value="เงินสด">เงินสด</MenuItem>
                <MenuItem value="เครดิต">เครดิต</MenuItem>
                <MenuItem value="30day_credit">เครดิต 30 วัน</MenuItem>
                <MenuItem value="60day_credit">เครดิต 60 วัน</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 pl-1 my-auto">
        <FormControlLabel
          value="end"
          defaultValue={addSupplier}
          control={<Checkbox color="primary" />}
          label="Supplier"
          labelPlacement="end"
          name="Supplier"
          onChange={() => {
            setAddSupplier(!addSupplier);
          }}
        />
      </div>
      {addSupplier ? (
        <>
          <div className="w-full px-1 pt-2">ข้อมูลบัญชีธุรกรรมการเงิน</div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'bank_name'}
              control={control}
              defaultValue={customer ? customer.bank_name : ''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อธนาคาร"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'bank_account_name'}
              control={control}
              defaultValue={customer ? customer.bank_account_name : ''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อบัญชี"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'bank_account_number'}
              control={control}
              defaultValue={customer ? customer.bank_account_number : ''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเลขบัญชี"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
        </>
      ) : (
        ''
      )}
      <div className="w-full lg:w-1/2 pl-1 my-auto">
        <FormControlLabel
          value="end"
          defaultValue={dropShip}
          control={<Checkbox color="primary" />}
          label="ตัวแทน (DropShip)"
          labelPlacement="end"
          name="drop_ship"
          onChange={() => {
            setDropShip(!dropShip);
          }}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'driver_line'}
          control={control}
          defaultValue={customer ? customer?.driver_line?.id : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                เส้นทาง
              </InputLabel>
              <Select
                {...field}
                label="เส้นทาง"
                // value={employee ? employee?.department?.id : ''}
                size={'small'}
                fullWidth
              >
                {_.size(driverLine?.rows) ? (
                  _.map(driverLine?.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {addUser != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            value="end"
            defaultValue={addUser}
            control={<Checkbox color="primary" />}
            label="สร้างบัญชีผู้ใช้เริ่มต้นให้ลูกค้า"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddUser(!addUser);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {addUser === true ? (
        <>
          <div className="w-full px-1 py-1">
            <Controller
              name={'username'}
              control={control}
              // defaultValue={employee ? employee.username : ''}
              rules={{ required: addUser }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้ใช้"
                  // value={employee ? employee.username : ''}
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="flex w-full">
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'password'}
                control={control}
                // defaultValue={employee ? employee.password : ''}
                rules={{ required: addUser }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="รหัสผ่าน"
                    type="password"
                    // value={employee ? employee.password : ''}
                    fullWidth
                    size={'small'}
                  />
                )}
              />
            </div>
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'confirmPassword'}
                control={control}
                // defaultValue={employee ? employee.password : ''}
                rules={{ required: addUser }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    // value={employee ? employee.password : ''}
                    fullWidth
                    size={'small'}
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

CustomerForm.propTypes = {
  customerType: PropTypes.object,
  errors: PropTypes.shape({
    name: PropTypes.object,
    address: PropTypes.object,
    organization_type: PropTypes.object,
    taxes: PropTypes.object,
    phone_number: PropTypes.object,
    fax: PropTypes.object,
    email: PropTypes.object,
    payment: PropTypes.object,
    source: PropTypes.object,
  }),
  customer: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  supplierType: PropTypes.bool,
  setSupplierType: PropTypes.func,
  addSupplier: PropTypes.bool,
  setAddSupplier: PropTypes.func,
  driverLine: PropTypes.object,
  setAddUser: PropTypes.func,
  addUser: PropTypes.bool,
  setDropShip: PropTypes.func,
  dropShip: PropTypes.bool,
};

CustomerForm.defaultProps = {
  customer: null,
};

export default CustomerForm;
