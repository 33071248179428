export const PRODUCT_TRANSACTION_TYPE = [
  {
    name: 'เข้า',
    direction: 'add',
  },
  {
    name: 'ออก',
    direction: 'desc',
  },
  {
    name: 'online',
    description: 'online',
  },
  {
    name: 'pos',
    description: 'pos',
  },
];

export const PRODUCT_TRANSACTION_TYPE_JSON = {
  ADD: {
    status_code: 'เข้า',
    direction: 'add',
  },
  OUT: {
    status_code: 'ออก',
    direction: 'desc',
  },
  ONLINE: {
    status_code: 'online',
    description: 'online',
  },
  POS: {
    status_code: 'pos',
    description: 'pos',
  },
};

export default PRODUCT_TRANSACTION_TYPE;
