/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  TextField,
} from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import dayjs from 'dayjs';

import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import QuotationPrint from '../../components/PdfFile/quotation';

const POSSummary = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const productTransaction = useSelector((state) => state.productTransaction);
  const setting = useSelector((state) => state.setting);

  const history = useHistory();
  // const product = useSelector((state) => state.product);

  // Setting React hook form
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(undefined);
  const [date, setDate] = useState(dayjs());

  const [startDate, setStartDate] = useState(new Date());

  console.log('startDate', startDate);
  const transactionType = 'pos';

  const handleToInfoPage = async (id) => {
    history.push(`/pos/exports/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(actions.productTransactionReset());
    dispatch(
      actions.productTransactionAll({
        transactionType,
        selectDate: dayjs(startDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(actions.settingAll());
    setLoading(false);

    return () => {};
  }, [startDate]);

  console.log('productTransaction', productTransaction);

  useEffect(() => {
    setTotal(productTransaction?.total);
    return () => {};
  }, [productTransaction]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePrintQuotation = (data) => {
    console.log('data print', data);
    console.log('setting', setting);

    const ArrayProductList = _.map(data?.order, (eachOrder) => {
      if (!eachOrder?.product?.option_type) {
        return { ...eachOrder, option: undefined };
      }
      return eachOrder;
    });

    console.log('ArrayProductList', ArrayProductList);

    QuotationPrint(data, setting, ArrayProductList);
  };

  const renderSearch = () => (
    <Card className="mb-2">
      <div className="w-full p-4 flex flex-row">
        <div className="w-full">
          <div className="bg-white flex justify-end ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                renderInput={(props) => <TextField {...props} size="large" />}
                label="เลือกเวลา"
                inputFormat={'DD MMMM BBBB '}
                value={dayjs(startDate)}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
          {/* <div className="bg-white">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="วันสิ้นสุด"
                value={endDate}
                minDate={new Date('2020-01-01')}
                onChange={(newValue) => {
                  setEndDate(dayjs(newValue).toISOString());
                  setLoading(true);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </div> */}
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">หมายเลขบิล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คู่ค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคารวม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.rows) ? (
                productTransaction.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.quotation && (
                        <div
                          className="bg-yellow-400 text-center cursor-pointer hover:bg-yellow-500"
                          onClick={() => handlePrintQuotation(row)}
                        >
                          ใบเสนอราคา
                        </div>
                      )}
                      <div>{row?.bill_no}</div>
                      <div className="mt-1">
                        {row?.payment_type === 'cash' && 'เงินสด'}
                      </div>
                      <div className="mt-1">
                        {row?.payment_type === 'transfer' && 'โอน'}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${
                        row?.customer?.name || '-'
                      }`}</div>
                      <div className="">{`${
                        row?.customer?.type?.name || '-'
                      }`}</div>
                    </TableCell>
                    <TableCell>
                      {row?.order.map((eachOrder, _index) => (
                        <>
                          <div key={_index} className="w-full py-1 flex">
                            <div className="font-bold flex">
                              <p className="pr-1 text-red-500">
                                {eachOrder?.amount} X{' '}
                              </p>
                              {eachOrder?.product?.name}
                              {eachOrder?.product?.option_type && (
                                <a className="pl-1">
                                  ( {eachOrder?.option?.name} )
                                </a>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">{`${row?.remark || '-'}`}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">
                        {(row?.total_price_offline_out_before || 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </TableCell>
                    <TableCell>{`${dayjs(row?.createdAt).format(
                      'D MMM BBBB ',
                    )}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          startIcon={<DescriptionIcon />}
                          size={'small'}
                          onClick={() => handleToInfoPage(row.id)}
                        >
                          รายละเอียด
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  if (
    productTransaction.isLoading ||
    !productTransaction.rows ||
    setting.isLoading
  ) {
    return <Loading />;
  }
  if (
    !productTransaction.isLoading &&
    productTransaction.isCompleted &&
    !setting.isLoading &&
    setting.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        <Card className="p-4">
          <div className="flex justify-between gap-2 my-2">
            <Button
              variant="outlined"
              startIcon={<ArrowCircleLeftIcon />}
              onClick={() => {
                setStartDate(dayjs(startDate).subtract(1, 'day').toDate());
              }}
            >
              <a>วันก่อนหน้า</a>
            </Button>
            <div className="text-center font-bold text-xl">{`${dayjs(
              startDate,
            ).format('DD MMMM BBBB ')}`}</div>
            <Button
              variant="outlined"
              endIcon={<ArrowCircleRightIcon />}
              onClick={() => {
                setStartDate(dayjs(startDate).add(1, 'day').toDate());
              }}
            >
              <a>วันถัดไป</a>
            </Button>
          </div>

          <div className="w-full md:flex">
            <div className="w-full md:w-1/2 flex justify-center text-center">
              <div>
                <div className="font-bold text-xl">รายได้</div>
                <div className="mt-2">
                  <a className="text-2xl text-red-600">
                    {_.sumBy(
                      productTransaction?.rows,
                      (row) => row?.total_price_offline_out_before || 0,
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  </a>
                  /{' '}
                  {(productTransaction?.totalPrice || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  บาท
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <div>
                <div className="font-bold text-xl">จำนวนออเดอร์</div>
                <div className="mt-2">
                  <a className="text-2xl text-red-600">
                    {(productTransaction?.total || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  </a>
                  /{' '}
                  {(productTransaction?.totalOrder || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  รายการ
                </div>
              </div>
            </div>
          </div>
        </Card>
        {renderTable()}
      </div>
    );
  }
  return <Error message={productTransaction?.message} />;
};

POSSummary.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

POSSummary.defaultProps = {
  title: '',
  subtitle: '',
};

export default POSSummary;
