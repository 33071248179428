import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'วันที่',
    value: 'createdAt',
  },
  {
    label: 'ชื่อสินค้า',
    value: 'product_name',
  },
  {
    label: 'เข้า/ออก',
    value: 'transaction_type',
  },
  {
    label: 'จำนวน',
    value: 'product_inventory',
  },
  {
    label: 'ก่อน',
    value: 'prev_quantity',
  },
  {
    label: 'คงเหลือ',
    value: 'next_quantity',
  },
  {
    label: 'ผู้ดำเนินการ/ลูกค้า',
    value: 'user',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
