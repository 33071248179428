/* eslint-disable arrow-body-style */
import React, { useRef } from 'react';
// eslint-disable-next-line import/order
import api from '../../utils/functions/api';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactQuill from 'react-quill';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-quill/dist/quill.snow.css';

import { Button } from '@mui/material';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

// eslint-disable-next-line react/prop-types
const RichtextEditor = ({ value, setValue, useImage }) => {
  const quillObj = useRef();
  const buttonRef = useRef();
  // const reactQuillRef = useRef();
  const input = document.createElement('input');

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const uploadImage = async (base64Image) => {
    try {
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/uploads`,
        {
          imageType: 'others',
          image: base64Image,
          alt: '',
        },
      );
      return data?.url;
    } catch (error) {
      console.error('Upload Have problem', error);
      return '';
    }
  };

  input.onchange = async () => {
    const file = input?.files?.[0];
    const imageData = await getBase64(file);
    //    console.log('File', file);
    //    console.log('Image Data', imageData);
    const uploadedImage = await uploadImage(imageData);
    console.log('Uploaded Image', uploadedImage);
    console.log(quillObj?.current);
    try {
      const range = quillObj?.current?.getEditorSelection();
      console.log('range ', range);
      quillObj?.current
        .getEditor()
        .insertEmbed(range.index, 'image', uploadedImage);
      console.log('value', value);
    } catch (error) {
      console.log('error ');
    }
  };

  const imageHandler = async () => {
    console.log('Image Prompt Open');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  };

  // const videoHandler = async () => {
  //   console.log('Video Prompt Open');

  //   const embedHtml = prompt('Please Enter the Html Embed');

  //   if (
  //     embedHtml &&
  //     embedHtml.match(
  //       /<iframe[^>]*?src="(?![^"]*(examle | google | ellak)).*?<\/iframe>/gim,
  //     )
  //   ) {
  //     const editor = reactQuillRef.getEditor();
  //     const index = editor.getSelection().index || 0;
  //     editor.insertEmbed(index, 'video', '^video_url^');
  //   }
  // };

  const CustomToolbar = () => (
    <div id="toolbar">
      {useImage && (
        <Button
          variant="contained"
          ref={buttonRef}
          className="ql-image"
          onClick={imageHandler}
          startIcon={<AddPhotoAlternateIcon />}
        >
          เพิ่มรูปภาพระหว่างข้อความ
        </Button>
      )}
    </div>
  );

  // const CustomToolbarVideo = () => (
  //   <div id="toolbar">
  //     <Button
  //       variant="contained"
  //       ref={buttonRef}
  //       onClick={videoHandler}
  //       startIcon={<AddPhotoAlternateIcon />}
  //     >
  //       เพิ่มลิงค์วีดีโอระหว่างข้อความ
  //     </Button>
  //   </div>
  // );

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text directio
        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, false] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['link', 'video'],
        ['clean'],
      ],
    },
  };

  const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video',
  ];

  return (
    <div className="">
      <CustomToolbar />
      {/* <CustomToolbarVideo /> */}
      <ReactQuill
        modules={modules}
        theme="snow"
        ref={quillObj}
        value={value || ''}
        // onChange={setValue}
        onChange={(content, delta, source, editor) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          setValue(editor.getHTML())
        }
        formats={formats}
        style={{ height: '400px' }}
      />
    </div>
  );
};

export default RichtextEditor;
