import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button, Autocomplete, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// ref : https://react-google-maps-api-docs.netlify.app/
// ref : https://www.npmjs.com/package/@react-google-maps/api

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
// import {
//   Combobox,
//   ComboboxInput,
//   ComboboxPopover,
//   ComboboxList,
//   ComboboxOption,
// } from '@reach/combobox';

// import '@reach/combobox/styles.css';

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EditCustomerForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const containerStyle = {
  width: '100%',
  height: '400px',
};

// const center = {
//   lat: 37.772,
//   lng: -122.214,
// };

const placesLibrary = ['places'];

export default function EditCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);
  const driverLine = useSelector((state) => state.driverLine);

  console.log('customer', customer);
  const [addSupplier, setAddSupplier] = useState(false);
  const [readySearch, setReadySearch] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [positionCurrent, setPositionCurrent] = useState({
    lat: parseFloat(customer?.position?.lat) || 13.7563309,
    lng: parseFloat(customer?.position?.lng) || 100.5017651,
  });
  const [positionCurrentCenter, setPositionCurrentCenter] = useState({
    lat: parseFloat(customer?.position?.lat) || 13.7563309,
    lng: parseFloat(customer?.position?.lng) || 100.5017651,
  });

  console.log('positionCurrent', positionCurrent);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      type: customer.type,
      name: customer.name,
      code_name: customer.code_name,
      organization_type: customer.organization_type,
      address: customer.address,
      taxes: customer.taxes,
      phone_number: customer.phone_number,
      fax: customer.fax,
      email: customer.email,
      payment: customer.payment,
      source: customer.source,
      bank_name: customer.bank_name,
      bank_account_name: customer.bank_account_name,
      bank_account_number: customer.bank_account_number,
    },
  });

  useEffect(() => {
    dispatch(actions.customerGet(id));
    dispatch(actions.customerTypeAll(''));
    dispatch(actions.driverLineAll(''));
    setTimeout(() => {
      setReadySearch(true);
    }, 500);
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (addUser === true) {
        if (data.password !== data.confirmPassword) {
          alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
        } else {
          const dataEnd = {
            ...data,
            supplier: addSupplier,
            add_user: addUser,
            position: {
              lat: positionCurrent?.lat,
              lng: positionCurrent?.lng,
            },
          };
          console.log('dataEnd with user', dataEnd);
          await dispatch(actions.customerPut(id, dataEnd));
          await dispatch(actions.customerGet(id));
          alert('สำเร็จ');
          history.goBack();
        }
      } else {
        const dataEnd = {
          ...data,
          supplier: addSupplier,
          position: {
            lat: positionCurrent?.lat,
            lng: positionCurrent?.lng,
          },
        };
        console.log('dataEnd', dataEnd);
        await dispatch(actions.customerPut(id, dataEnd));
        await dispatch(actions.customerGet(id));
        alert('สำเร็จ');
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onLoad = (marker) => {
    console.log('marker: ', marker);
  };

  useEffect(() => {
    if (customer?.position?.lat) {
      setPositionCurrent({
        lat: parseFloat(customer?.position?.lat),
        lng: parseFloat(customer?.position?.lng),
      });
      setPositionCurrentCenter({
        lat: parseFloat(customer?.position?.lat),
        lng: parseFloat(customer?.position?.lng),
      });
    }
    return () => {};
  }, [customer]);

  const centerMemo = useMemo(() => ({ lat: 13.7563309, lng: 100.5017651 }), []);
  const [selected, setSelected] = useState(null);

  console.log('selected out search', selected);

  const PlacesAutocomplete = () => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const [dataAddress, setDataAddress] = useState([]);

    console.log('data in search', data, status);
    console.log('ready in search', ready);

    console.log('value in search', value);

    useEffect(() => {
      setDataAddress(data);
      return () => {};
    }, [data]);

    const handleSelect = async (address) => {
      console.log('address in handle select', address);
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      console.log('results in search', results);
      const { lat, lng } = getLatLng(results[0]);
      setSelected({ lat, lng });
      setPositionCurrent({ lat, lng });
      setPositionCurrentCenter({ lat, lng });
    };

    return (
      <>
        <Autocomplete
          options={dataAddress || []}
          getOptionLabel={(option) => option?.description}
          renderOption={(props, type) => (
            <div
              className=""
              {...props}
              onClick={() => handleSelect(type?.description)}
            >
              <div className="my-auto pl-2">{`${type?.description}`}</div>
            </div>
          )}
          onInputChange={(event, newValue) => {
            console.log('newValue in auto', newValue);
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label="ค้นหาสถานที่ใกล้เคียง"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        {/* <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          // disabled={!ready}
          placeholder="Search an address"
        />
        {status === 'OK' && (
          <div className="bg-gray-400">
            {dataAddress.map((e) => (
              <div
                key={e?.place_id}
                value={e?.description}
                onClick={() => handleSelect(e?.description)}
              >
                {e?.description}
              </div>
            ))}
          </div>
        )} */}
      </>
    );
  };

  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลลูกค้า</div>
        </div>
        <div className="flex flex-wrap">
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditCustomerForm
                errors={errors}
                customerType={customerType}
                customer={customer}
                control={control}
                Controller={Controller}
                addSupplier={addSupplier}
                setAddSupplier={setAddSupplier}
                driverLine={driverLine}
                setAddUser={setAddUser}
                addUser={addUser}
              />
              <div className="places-container mb-2 p-1">
                {readySearch && <PlacesAutocomplete />}
              </div>
              <div id="google-map" className="p-1">
                <LoadScript
                  googleMapsApiKey="AIzaSyAp-85dLfh4ua90wz5TmIOmqoaA64h4b1U"
                  libraries={placesLibrary}
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={positionCurrentCenter || centerMemo}
                    zoom={10}
                  >
                    {positionCurrent && (
                      <Marker
                        onLoad={onLoad}
                        position={positionCurrent}
                        draggable={true}
                        onDrag={(e) => {
                          setPositionCurrent({
                            lat: e?.latLng.lat(),
                            lng: e?.latLng.lng(),
                          });
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
              <div className="flex justify-between w-full">
                <div className="">lat : {positionCurrent?.lat}</div>
                <div className="">lng : {positionCurrent?.lng}</div>
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
