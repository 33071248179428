/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  Backdrop,
  Fade,
  Modal,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Paper,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import pdfMake from 'addthaifont-pdfmake/build/pdfmake';

import PrintIcon from '@mui/icons-material/Print';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import OrderNoVatSlip from '../../components/PdfFile/OrderNoVatSlip';
import CoverPrint from '../../components/PdfFile/Cover';
import CoverVerticalPrint from '../../components/PdfFile/CoverVertical';
import QuotationPrint from '../../components/PdfFile/quotation';
import InvoicePrint from '../../components/PdfFile/InvoiceExport';
import ReceiptNoVat from '../../components/PdfFile/ReceiptExportNoVat';
import ReceiptVatInclude from '../../components/PdfFile/ReceiptExportVatInclude';
import ReceiptVatExclude from '../../components/PdfFile/ReceiptExportVatExclude';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

export default function DetailExport({ title, subtitle }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const me = useSelector((state) => state.me);

  const { id } = useParams();
  const productTransaction = useSelector((state) => state.productTransaction);
  const [selectSerial, setSelectSerial] = useState([]);
  const [openSerial, setOpenSerial] = useState(false);

  const handleOpenSerial = () => setOpenSerial(true);
  const handleCloseSerial = () => setOpenSerial(false);

  console.log('productTransaction', productTransaction);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.settingAll());
    const fetchProductTransaction = async () => {
      try {
        await dispatch(actions.productTransactionGet(id));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSerialNumber = async () => {
      try {
        await dispatch(actions.getAllSerial(''));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProductTransaction();
    fetchSerialNumber();

    return () => {};
  }, []);

  const { order } = productTransaction;
  // eslint-disable-next-line consistent-return
  function checkSerial(data, orderId) {
    handleOpenSerial();
    const findSerial = _.filter(order, {
      product: data,
      _id: orderId,
    });
    setSelectSerial(findSerial);
  }

  // const handlePrintQuotation = (data) => {
  //   console.log('data print', data);
  //   console.log('setting', setting);
  //   console.log('ArrayProductList', order);

  //   QuotationPrint(data, setting, order);
  // };

  console.log('ArrayProductList', order);
  console.log('paymentTransaction', productTransaction);
  const handlePrintCover = () => {
    CoverPrint(productTransaction, setting, 'export');
  };

  const handlePrintCoverVertical = () => {
    CoverVerticalPrint(productTransaction, setting, 'export');
  };

  const handlePrintReceiptNoVat = () => {
    ReceiptNoVat(productTransaction, setting, order, 'export');
  };

  const handlePrintReceiptVatInclude = () => {
    ReceiptVatInclude(productTransaction, setting, order, 'export');
  };

  const handlePrintReceiptVatExclude = () => {
    ReceiptVatExclude(productTransaction, setting, order, 'export');
  };

  const renderPrintButton = () => (
    <Card className="">
      <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
        <InputLabel id="demo-select-small">
          <div className="w-full flex justify-between">
            <p className="pr-2">เลือกการพิมพ์</p> <PrintIcon />
          </div>
        </InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          label="เลือกการพิมพ์"
        >
          <MenuItem
            onClick={() => {
              handlePrintCover();
            }}
          >
            ใบปะหน้าพัสดุ (แนวตั้ง)
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintCoverVertical();
            }}
          >
            ใบปะหน้าพัสดุ (แนวนอน)
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintReceiptNoVat();
            }}
          >
            ใบเสร็จรับเงิน (NO VAT)
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintReceiptVatInclude();
            }}
          >
            ใบเสร็จรับเงิน (VAT INCLUDE)
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintReceiptVatExclude();
            }}
          >
            ใบเสร็จรับเงิน (VAT EXCLUDE)
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              handlePrintPurchase();
            }}
          >
            พิมพ์ใบสั่งซื้อ
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintAll();
            }}
          >
            พิมพ์ทั้งหมด
          </MenuItem> */}
        </Select>
      </FormControl>
    </Card>
  );

  const renderModalSerialNumber = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openSerial}
      onClose={handleCloseSerial}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openSerial}>
        <Card sx={style} className="max-w-xl">
          <div className="pb-2 font-bold">รายการ Serial Number</div>
          <div className="max-h-80 overflow-y-auto border-2">
            <Paper>
              <TableContainer component={Paper}>
                <Table size="small" className="border-lg">
                  <TableHead className="bg-yellow-100">
                    <TableRow>
                      <TableCell>
                        <div className="font-bold">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold"> Serial Number</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectSerial.map(
                      (eachSerial) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        eachSerial.serial.map((eachSerialName, index) => (
                          // eslint-disable-next-line react/jsx-key
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            className={index % 2 === 0 ? 'bg-gray-100' : ''}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{eachSerialName.name}</TableCell>
                          </TableRow>
                        )),
                      // eslint-disable-next-line function-paren-newline
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const displayProduct = () => (
    <div className="">
      <Paper>
        <TableContainer>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคาต่อหน่วย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction) ? (
                order.map((_order, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="flex">
                        <div className="my-auto">
                          <div>{_order?.product?.name}</div>
                          <div>
                            {_order?.remark && <div>( {_order?.remark} )</div>}
                          </div>
                        </div>
                        {_order?.serial.length === 0 ? (
                          <div className="py-5"></div>
                        ) : (
                          <div className="my-auto">
                            <Tooltip
                              title="ดู serial number"
                              placement="right-start"
                            >
                              <IconButton
                                color="primary"
                                aria-label="more detail product"
                                component="span"
                                // eslint-disable-next-line no-underscore-dangle
                                onClick={() =>
                                  checkSerial(_order?.product, _order?._id)
                                }
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {_order?.amount} {_order?.product?.unit}
                    </TableCell>
                    <TableCell>
                      {_order?.discount ? (
                        <div className="">
                          <div className="line-through text-sm text-gray-300">
                            {`${parseFloat(_order?.price).toLocaleString(
                              undefined,
                            )}`}{' '}
                            {'บาท'}
                          </div>
                          <div>
                            {`${parseFloat(
                              _order?.price - _order?.discount,
                            ).toLocaleString(undefined)}`}{' '}
                            {'บาท'}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {`${parseFloat(_order?.price).toLocaleString(
                            undefined,
                          )}`}{' '}
                          {'บาท'}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีรายการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderShowProductList = () => (
    <Card className="p-6 ">{displayProduct()}</Card>
  );

  const handlePrintSlip = () => {
    console.log('productTransaction', productTransaction);

    const noVatSlip = OrderNoVatSlip(
      productTransaction?.customer,
      setting,
      productTransaction?.order,
      me?.userData,
      productTransaction?.payment_type,
      productTransaction?.discount_bill,
      'export',
      productTransaction,
    );
    pdfMake.createPdf(noVatSlip).open();
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productTransaction.isLoading || productTransaction.rows) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderModalSerialNumber()}
        <div className="flex flex-row justify-between pb-4">
          <div className=" my-auto">
            <BackButton />
          </div>
          {/* <div className="text-lg font-semibold ml-6">รายละเอียดการส่งออก</div> */}
          {renderPrintButton()}
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'คู่ค้า'}</div>
              <div className="w-1/2 py-4 ">{`${
                productTransaction?.customer?.name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทคู่ค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                productTransaction?.customer?.type?.name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้ดำเนินการ'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransaction?.employee?.firstname}` || '-'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขบิล'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransaction?.bill_no}` || '-'}
              </div>
              {/* {productTransaction?.detail ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'รายละเอียด'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${productTransaction?.detail}`}</div>
                </>
              ) : (
                <></>
              )} */}
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เวลาดำเนินการ'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${dayjs(productTransaction?.date).format(
                  'D MMM BBBB เวลา HH:mm',
                )}`}{' '}
              </div>
              {productTransaction?.discount_bill && (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ส่วนลดท้ายบิล'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    {`${productTransaction?.discount_bill}` || '-'} บาท
                  </div>
                </>
              )}
              {productTransaction?.remark ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'หมายเหตุ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${productTransaction?.remark}`}</div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
          {productTransaction?.order ? (
            <>
              <div className="w-full py-4 px-2 font-semibold flex justify-between">
                <div>{'รายการสินค้า'}</div>
                <div className="">
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={() => handlePrintSlip()}
                  >
                    พิมพ์ใบเสร็จ (แบบย่อ)
                  </Button>
                </div>
              </div>
              {renderShowProductList()}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailExport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailExport.defaultProps = {
  title: '',
  subtitle: '',
};
