import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ReportHeaderStock } from './common';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (Products) => {
  const productList = _.map(Products, (product, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: dayjs(product?.date).format('DD MMM BB  HH:mm'),
      alignment: 'center',
    },
    { text: `${product?.product_type?.name}\n` },
    { text: product?.transaction_type, alignment: 'center' },
    { text: product?.quantity, alignment: 'center' },
    { text: product?.prev_quantity, alignment: 'center' },
    { text: product?.next_quantity, alignment: 'center' },
    { text: product?.user },
  ]);

  return productList;
};

const stockHistory = async (logs, setting) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  const namePage = 'รายงานประวัติการจัดการสต๊อก';
  console.log('logs pdf', logs);
  console.log('setting in stock history', setting);
  const genItemBody = genBody(logs?.rows);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 100, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [ReportHeaderStock(setting)],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '18%', '31%', '8%', '6%', '6%', '6.5%', '18%'],
          body: [
            [
              {
                text: 'ลำดับ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วันที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ชื่อสินค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'เข้า/ออก',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ก่อน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'คงเหลือ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ผู้ดำเนินการ/ลูกค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default stockHistory;
